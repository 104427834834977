import "./bootstrap";
import "../css/applicant.css";

import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/vue3";
import { ZiggyVue } from "../../vendor/tightenco/ziggy";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import * as Sentry from "@sentry/vue";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

createInertiaApp({
    title: (title) => `${title} - scaia`,
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue"),
        ),

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(ZiggyVue, Ziggy)
            .use(VueTippy, { directive: "tippy" });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN_PUBLIC,
            integrations: [
                 Sentry.browserTracingIntegration({Ziggy}),
            ],
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/scaia\.sustainabilitychallenge\.at/,
                /^https:\/\/scaia-staging\.sustainabilitychallenge\.at/,
            ],
            tracesSampleRate: 0.5,
        });

        return app
            .mount(el);
    },
    progress: {
        color: "#4B5563",
    },
});
